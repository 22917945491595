import $ from '@vaersaagod/tools/Dom';
import Viewport from '@vaersaagod/tools/Viewport';
import { loadFlickity } from '../lib/async-bundles';

export default el => {
    const $el = $(el);

    let Flickity = null;
    let flkty = null;
    
    const initFlickity = () => {
        loadFlickity(flickity => {
            Flickity = flickity;
            flkty = new Flickity(el, {
                pageDots: false,
                adaptiveHeight: false,
                prevNextButtons: false,
                setGallerySize: true,
                groupCells: false,
                freeScroll: true,
                contain: true,
                cellAlign: 'left',
                resize: true,
                wrapAround: false,
                percentPosition: true,
                accessibility: false,
                dragThreshold: 10,
                lazyLoad: false,
                on: {
                    dragStart: () => {
                        $el.find('a,button').css({ pointerEvents: 'none' });
                    },
                    dragEnd: () => {
                        $el.find('a,button').css({ pointerEvents: '' });
                    }
                }
            });
            
            setTimeout(() => {
                flkty.resize();
            }, 100);
        });
    };
    
    const onBreakpoint = e => {
        const { detail } = e;
        
        if (detail.current.size >= 750 && flkty !== null) {
            flkty.destroy();
            flkty = null;
        }
        if (detail.current.size < 750 && flkty === null) {
            initFlickity();
        }
    };
    
    const init = () => {
        if (Viewport.breakpoint.size < 750) {
            initFlickity();
        }
        
        Viewport.on('breakpoint', onBreakpoint);
    };

    const destroy = () => {
        if (flkty) {
            flkty.destroy();
        }
    };

    return {
        init,
        destroy
    };
};

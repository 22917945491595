import $ from '@vaersaagod/tools/Dom';
import gsap from 'gsap';
import Draggable from 'gsap/dist/Draggable';
import Viewport from '../core/Viewport';

gsap.registerPlugin(Draggable);

export default el => {
    const $el = $(el);
    const $tablist = $el.find('[data-nav-list]');
    const $tablistItems = $tablist.find('[data-nav-list-item]');
    const $tabs = $tablist.find('[data-tab]');
    const $panels = $el.find('[data-panel]');

    let dragger = null

    // The tab switching function
    const switchTab = (oldTab, newTab) => {
        newTab.focus();
        // Make the active tab focusable by the user (Tab key)
        newTab.removeAttribute('tabindex');
        // Set the selected state
        newTab.setAttribute('aria-selected', 'true');
        oldTab.removeAttribute('aria-selected');
        oldTab.setAttribute('tabindex', '-1');
        // Get the indices of the new and old tabs to find the correct
        // tab panels to show and hide
        const index = Array.prototype.indexOf.call($tabs.nodes, newTab);
        const oldIndex = Array.prototype.indexOf.call($tabs.nodes, oldTab);
        $panels.get(oldIndex).hidden = true;
        $panels.get(index).hidden = false;
    };

    const updateDragger = () => {
        if (dragger !== null) {
            const diff = $tablist.width() - $tablist.parent().width();

            dragger.applyBounds({
                minX: Math.min(-diff, 0),
                maxX: 0
            });
            
            dragger.update(true);
        }
    };

    const onResize = e => {
        updateDragger();
    };

    const init = () => {
        $tablist.attr('role', 'tablist');
        $tablistItems.attr('role', 'presentation');
        $tabs.attr('role', 'tab');
        $tabs.attr('tabindex', '-1');

        $tabs.on('click', e => {
            e.preventDefault();
            const $currentTab = $tabs.filter('[aria-selected]');

            if (e.currentTarget !== $currentTab.get(0)) {
                switchTab($currentTab.get(0), e.currentTarget);
            }
        });

        $tabs.each((item, i) => {
            const $item = $(item);

            $item.on('keydown', e => {
                const index = Array.prototype.indexOf.call($tabs.nodes, e.currentTarget);

                let dir = null;

                if (e.keyCode === 37) {
                    dir = index - 1;
                } else if (e.keyCode === 39) {
                    dir = index + 1;
                } else if (e.keyCode === 40) {
                    dir = 'down';
                }

                if (dir !== null) {
                    e.preventDefault();

                    if (dir === 'down') {
                        $panels.get(i).focus();
                    } else if ($tabs.get(dir)) {
                        switchTab(e.currentTarget, $tabs.get(dir));
                    }
                }
            });
        });

        // Add tab panel semantics and hide them all
        $panels.attr('role', 'tabpanel');
        $panels.attr('tabindex', '-1');
        $panels.attr('hidden', '');

        $panels.each((item, i) => {
            const $item = $(item);
            $item.attr('aria-labelledby', $tabs.get(i).id);
        });

        // Initially activate the first tab and reveal the first tab panel
        $tabs.eq(0).attr('tabindex', null);
        $tabs.eq(0).attr('aria-selected', 'true');
        $panels.eq(0).attr('hidden', null);

        // init draggable
        $tablist.addClass('whitespace-no-wrap inline-block').removeClass('flex flex-wrap');

        dragger = Draggable.create($tablist.get(0), {
            type: 'x',
            bounds: {
                minX: 0,
                maxX: 0
            }
        })[0];

        updateDragger();

        Viewport.on('resize', onResize);
    };

    const destroy = () => {
        $tabs.off('click');
        $tabs.off('keydown');

        if (dragger !== null) {
            dragger.kill();
            dragger = null;
        }
    };

    return {
        init,
        destroy
    };
};

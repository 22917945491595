import $ from '@vaersaagod/tools/Dom';
import Viewport from '@vaersaagod/tools/Viewport';
import Config from '@vaersaagod/tools/Config';
import gsap from 'gsap';

export default (el, props) => {
    const BP_HOVER_ENABLED = 1200;

    const $el = $(el);
    const $items = $el.find('a');
    const $marker = $el.find('[data-underline-bar]');
    
    let $selected = null;
    let isActive = true;
    let isVisible = false;
    let isEnded = false;
    let resetTimeout = -1;

    const end = () => {
        isEnded = true;
    };
    
    const reset = () => {
        if (!isEnded) {
            if ($selected && $selected.length > 0) {
                scrollToTarget($selected);
            } else {
                gsap.to($marker.nodes, { opacity: 0, scaleX: 0, duration: 0.4, ease: 'quint.out' });
                isVisible = false;
            }
        }
    };
    
    const scrollToTarget = $target => {
        if (!isEnded) {
            const width = $target.width();
            const { left } = $target.position();
            
            if (isVisible) {
                gsap.to($marker.nodes, { duration: 0.6, width, left, ease: 'quint.out' });
            } else {
                $marker.css({ width, left });
                gsap.set($marker.nodes, { scaleX: 0 });
                gsap.to($marker.nodes, { duration: 0.6, scaleX: 1, opacity: 1, ease: 'quint.out' });
                isVisible = true;
            }
        }
    };

    const onBreakpoint = () => {
        const wasActive = isActive;
        isActive = Viewport.width >= BP_HOVER_ENABLED;

        if (isActive) {
            reset();
        }
    };

    const init = () => {
        $marker.css({ display: 'block', opacity: 0 });

        $items.on('mouseenter', e => {
            clearTimeout(resetTimeout);
            
            const $target = $(e.triggerTarget);
            scrollToTarget($target);
        });

        $items.on('mouseleave', e => {
            clearTimeout(resetTimeout);
            resetTimeout = setTimeout(() => {
                reset();
            }, 500);
        });
        
        $items.on('click', e => {
            end();
        });
        
        if (!Config.get('isFrontpage')) {
            const requestUrl = Config.get('requestUrl');
            
            $items.each(item => {
                const $item = $(item);
                const href = $item.attr('href');
                
                if (requestUrl.startsWith(href)) {
                    $selected = $item;
                }
            });
        }

        setTimeout(() => {
            if ($selected !== null && $selected.length > 0) {
                $marker.css({ width: $selected.width(), left: $selected.position().left, opacity: 1 });
                isVisible = true;
            }
        }, 50);

        Viewport.on('breakpoint', onBreakpoint);
    };

    const destroy = () => {
        $items.off('mouseenter');
        $items.off('mouseleave');
        $items.off('click');
        
        Viewport.off('breakpoint', onBreakpoint);
    };

    return {
        init,
        destroy
    };
};
